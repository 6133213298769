<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                     <div class="columns">
                        <div class="column is-10">
                            <periodoHora
                            :dataInicial.sync="dataInicial"
                            :dataFinal.sync="dataFinal"
                            :placeHolder="$t('SALAESPERA.SELECIONEPERIODO')"
                            :selecionadas="[dataInicial,dataFinal]"
                            />
                        </div>
                        <div class="column is-2">
                            <button class="button is-primary" type="button" @click="filtrar({dataInicial, dataFinal})">{{$t('AGENDARELACAO.FILTRAR')}}</button>
                        </div>
                    </div>
                    <div class="columns">

                        <div class="column is-3">
                            <b>{{$t('SALAESPERA.FILTRARAGENDAMENTO')}}</b>
                            <b-select v-model="tipoAgendamentoIdFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.tiposAgendamentoAtivosFiltro">
                                    <option v-if="tipoAgendamentoIdFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3">
                            <b>{{$t('SALAESPERA.FILTRARSTATUS')}}</b>
                            <b-select v-model="statusFiltro" expanded>
                                <template v-for="(item, index) in this.statusAgenda">
                                    <option v-bind:key="index" :value="item.valor">
                                        {{mapStatusAgenda(item.descricao)}}
                                    </option>
                                </template>
                                <option :value="todos">Todos</option>
                            </b-select>
                        </div>  
                        <div class="column is-3">
                            <b-field :label="$t('SALAESPERA.ATUALIZAR')">
                                <b-select v-model="intervalo" @input="setTimer" expanded>
                                    <option value="0">Nunca</option>
                                    <option value="1">Cada minuto</option>
                                    <option value="2">Cada 2 minutos</option>
                                    <option value="5">Cada 5 minutos</option>
                                    <option value="10">Cada 10 minutos</option>
                                    <option value="20">Cada 20 minutos</option>
                                    <option value="30">Cada 30 minutos</option>
                                </b-select>
                            </b-field>
                        </div>                                                
                    </div>

                     <br />

                    <b-table :data="model"  >
                        <template >
                            <b-table-column v-slot="props" field="ordem" :label="$t('SALAESPERA.ORDEM')">                                
                                <p>{{ props.row.contador }}</p>
                            </b-table-column>
                            <b-table-column v-slot="props" field="guia" :label="$t('SALAESPERA.NUMEROGUIA')">
                                
                                <router-link :to="{ name: 'guia', params: { id: props.row.guiaId }}">
                                    {{ props.row.guiaId }}
                                </router-link>                                   
                                <b-tag v-show="props.row.necessidadesEspeciais" type="guia-indicador-necessidadesespeciais" size="is-small">Necessidade Especiais</b-tag>
                                <b-tag v-show="props.row.idadeInteiro > 60" type="guia-indicador-idoso" size="is-small"> 60 anos</b-tag>
                                <p>{{ props.row.dataHora | datahora}}</p>
                            </b-table-column>                            
                            <b-table-column v-slot="props" field="paciente" :label="$t('SISTEMA.PACIENTE')">
                                <router-link 
                                    target='_blank'
                                    :to="{ name: 'prontuario', params: { id: props.row.pacienteId }}"
                                >
                                    {{ props.row.pacienteNome}} - {{ props.row.pacienteSexo}}
                                </router-link> 
                                <p v-if="config.laboratorioVeterinario && props.row.pacienteRaca" >
                                    <b>{{ props.row.pacienteRaca }}/{{ props.row.pacienteEspecie }}</b>
                                </p>                                                                   
                                <p v-if="props.row.pacienteResponsavelNome" >
                                    <b>{{ props.row.pacienteResponsavelNome}}</b>
                                </p>                                
                            </b-table-column> 

                            <b-table-column v-slot="props" field="executante" :label="$t('SALAESPERA.EXECUTANTE')">
                                <div class="column is-8">
                                    <b-select @change.native="salvarExecutante(props.row.executanteId, props.row.agendamentoId, $event)" v-model="props.row.executanteId" :disabled="isInRole('saladeespera-alteraexecutante') ? false : true" expanded >
                                        <template v-for="(item) in funcionariosAtivosFiltro">
                                            <option v-bind:key="item.id" :value="item.id">
                                                {{item.nome}}
                                            </option>
                                        </template>
                                    </b-select>
                                </div>               
                            </b-table-column> 

                            <b-table-column v-slot="props" field="idade" :label="$t('SALAESPERA.IDADE')">
                                {{ props.row.idade}}                                          
                            </b-table-column>                                                      
                            <b-table-column v-slot="props" field="tipoagendamento" :label="$t('SALAESPERA.TIPOAGENDAMENTO')">
                                {{ props.row.tipoAgendamentoNome}}
                                <b-icon v-if="props.row.tipoAgendamentoCor"
                                        :style="tipoAgendamentoStyle(props.row)"    
                                        size="is-small"                                            
                                        icon="square-rounded"></b-icon>
                                <p>  {{ props.row.statusSalaEspera}}</p>                                 
                            </b-table-column>  
                            <b-table-column v-slot="props" field="log" :label="$t('SALAESPERA.ACAO')">  
                                    <b-button v-if="props.row.statusSalaEspera=='Aguardando'" type="is-success" native-type="button" :title="$t('SALAESPERA.ATENDIMENTO')" 
                                    icon-left="human-handsdown"  class="button-delete"  @click="realizarAtendimento(props.row)" ></b-button>  

                                    <b-button v-if="props.row.statusSalaEspera=='Em Atendimento'" type="is-warning" native-type="button" :title="$t('SALAESPERA.CHECKOUT')"
                                     icon-left="account-check" class="button-delete" style="margin-left: 2px" @click="realizarCheckout(props.row)" ></b-button>    

                                    <b-button type="is-info" native-type="button" :title="$t('SALAESPERA.HISTORICO')" 
                                    icon-left="clock" class="button-delete" style="margin-left: 2px" @click="exibirHistorico(props.row)" ></b-button>    

                                    <b-button v-if="props.row.temPesquisa" tag="router-link" type="has-text-info" :title="$t('SALAESPERA.PESQUISA')" 
                                    icon-left="information" style="margin-left: 2px" 
                                    :to="{name: 'pesquisaclinica', params: {id: props.row.guiaId, pesquisaExecucao: true,}}" 
                                    ></b-button>                            
                            </b-table-column>                                                                                                                                                                                                                                

                        </template>
                    </b-table>
                </article>
            </div>
        </div>        
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import periodoHora from '@/components/periodoHora.vue'
    import moment from 'moment';    
    import historicoAlteracoes from "@/components/historicoAlteracoes.vue";
    import { mapState, mapGetters } from 'vuex';  

    export default {
        components: {
            titlebar,
            periodoHora            
        },
        computed: {
            ...mapState([
                'config',
                'usuario'
            ]),            
            titleStack() {
                return [
                    this.$t('SALAESPERA.ATENDIMENTO'),
                    this.$t('SALAESPERA.SALAESPERA'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
        },
        data() {
            return {
                intervalo: 0,
                dataInicial:  moment().set('hours',0).set('minutes',0).toDate(),
                dataFinal: moment().set('hours',23).set('minutes',59).toDate(),
                model: [],                                
                item: {
                    tipoAgendamento: null,
                    executanteId: null,
                },
                funcionarioIdFiltro: null,
                funcionariosAtivosFiltro: [],
                tipoAgendamentoIdFiltro: null,
                statusFiltro: null,
                tiposAgendamentoAtivosFiltro: [],
                statusAgenda:[],
                isLoading: false,
                loadedData: false,
                todos: null,
            }
        },       
        created(){
            try {
                if (this.$route.query.dataInicial) this.dataInicial = moment(this.$route.query.dataInicial,'DD/MM/YYYY HH:mm').toDate();
                if (this.$route.query.dataFinal) this.dataFinal = moment(this.$route.query.dataFinal,'DD/MM/YYYY HH:mm').toDate();            
                if (this.$route.query.tipoAgendamentoId) this.tipoAgendamentoIdFiltro = this.$route.query.tipoAgendamentoId; 

                if(this.statusFiltro==null) this.statusFiltro = 256; 

                this.loadData();
            }catch(ex){
                console.error(ex);
            }
        },
        mounted() {
            this.$http.get('/api/search/FuncionariosAtivos?apenasCrmCadastrado=true')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.funcionariosAtivosFiltro = data;
                }
            });

            this.$http.get('/api/search/ListarStatusAgenda?salaEspera=true')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.statusAgenda = data;
                }
                this.statusAgenda.sort(status => {
                    if(status.descricao == "CheckinEmAtendimento"){
                        return -1;
                    }
                })
            });

            this.$http.get('/api/search/tiposagendamentos' + 
                (this.$route.query.unidadeId != null ? `?unidadeId=${this.$route.query.unidadeId}` : ''))
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.tiposAgendamentoAtivosFiltro = data;
                }
            });            
        },
        beforeDestroy() {
            if (this.timer != null) {
                clearInterval(this.timer)
            }
        },        
        filters: {
            datahora:  (valor) => {
            if (!valor) return null;    
            
            return  moment(valor).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        methods: { 
           mapStatusAgenda(status) {
                if(status == "CheckinEmAtendimento"){
                    return "Checkin e Em Atendimento";
                }else if (status == "EmAtendimento"){
                    return "Em Atendimento";
                }else{
                    return status;
                }
                
            },
            setTimer() {
                if (this.timer != null) {
                    clearInterval(this.timer)
                }
                if (this.intervalo > 0) {
                    this.timer = setInterval(this.loadData, this.intervalo * 60 * 1000)
                }
            },            
            async agendaStatus(evento,acao) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);

                params.push(`acao=${acao}`);

                try {
                    const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                    const res = await this.$http.get(url);
                    this.loadData();
                } catch (e) {
                    console.error(e);
                }

            },
            realizarAtendimento(evento){
                this.$buefy.dialog.confirm({
                    title: 'Confirmar',
                    message: this.$t('SALAESPERA.REALIZARATENDIMENTO'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {
                        const item = {id: evento.id};
                        this.agendaStatus(item,'atendimento')
                    }
                })
            },  
            realizarCheckout(evento){
                this.$buefy.dialog.confirm({
                    title: 'Confirmar',
                    message: this.$t('SALAESPERA.FAZERCHECKOUT'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {
                        const item = {id: evento.id};
                        this.agendaStatus(item,'checkout')
                    }
                })
            },                                
            
            tipoAgendamentoStyle(evento) {
                return 'color: #' + evento.tipoAgendamentoCor + '!important'
            },              
            exibirHistorico(item) {
                this.$buefy.modal.open({
                    parent: this,
                    component: historicoAlteracoes,
                    props: {
                        tabela: '',
                        chave: item.id,
                        toggle: true,
                        agendaLog: true,
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },            
            filtrar(datas) {
                this.dataInicial = datas.dataInicial;
                this.dataFinal = datas.dataFinal;
                this.loadData();
            },

            loadData(){
                const params = [];
                if (this.dataInicial != null) params.push(`dataInicial=${moment(this.dataInicial).format()}`);
                if (this.dataFinal != null) params.push(`dataFinal=${moment(this.dataFinal).format()}`);
                if (this.statusFiltro != null) params.push(`status=${this.statusFiltro}`);
                if (this.tipoAgendamentoIdFiltro != null) params.push(`tipoAgendamentoId=${this.tipoAgendamentoIdFiltro}`);
                if (this.funcionarioIdFiltro != null) params.push(`funcionarioId=${this.funcionarioIdFiltro}`);                
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);            
                
                this.isLoading = true;
                try {
                    this.$http.get(`/api/agenda/RelacaoSalaEspera?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.model = data?.map( (d,index) => {
                                    return {
                                        ...d,
                                        dataHora: moment(d.dataHora).toDate(),
                                        idadeInteiro: parseInt(d.idade.split(" ")[0]) ,
                                        contador: index                                                                           
                                    }
                                });
                            }
                            this.isLoading = false;
                            this.loadedData = true;
                        }).catch(e => {
                            throw e;
                        });
                }catch(e){
                    console.error(e);
                    this.isLoading = false;
                }

            },
            salvarExecutante(executanteId, agendaId, event){
                this.loading = true;

                this.$buefy.toast.open({
                duration: 2000,
                message: this.$t('SALAESPERA.SALVANDODADOS'),
                type: 'is-info',
                queue: false
                });

                this.$http.post(`/api/agenda/salvarExecutanteSalaEspera?executanteId=${executanteId}&agendaId=${agendaId}`)
                .then(() => {
                    event.target.blur(); 
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                }); 

            },
           
        }
    }
</script>