<template>
    <b-field :label="$t('BUSCACIDADE.CIDADE')" :type="{ 'is-danger': temErros, 'is-success': valido }">
        <b-autocomplete :data="data"
                        field="nome"
                        :value="cidadenome"
                        :loading="isFetching"
                        @typing="getAsyncData"
                        @select="selectCidade">
            <template slot="empty">
                {{$t('BUSCACIDADE.NENHUMACIDADE')}}
            </template>
            <template slot-scope="props">
                <div class="is-flex is-justified-between">
                    <span>{{ props.option.nome }}</span>
                    <span class="tag">{{ props.option.id }}</span>
                </div>
            </template>
        </b-autocomplete>
    </b-field>
</template>

<script>
    import debounce from 'lodash/debounce'

    export default {
        props: {
            cidadeid: Number,
            cidadenome: String,
            temErros: String,
            valido: Boolean
        },
        data() {
            return {
                data: [],
                selected: null,
                isFetching: false
            }
        },
        methods: {
            getAsyncData: debounce(function (name) {
                if (!name.length) {
                    this.data = []
                    return
                }
                this.isFetching = true
                this.$http.get(`/Search/Cidade?nome=${name}`)
                    .then(({ data }) => {
                        this.data = []
                        data.forEach((item) => this.data.push(item))
                    })
                    .catch((error) => {
                        this.data = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),

            selectCidade(option) {
                this.selected = option
                this.$emit('changed', option?.id)                
                this.$emit('update:cidadeid', option.id)
            }
        }
    }
</script>