<template>
    <div v-if="this.$props.parcela != null">
        <b-button type.native="button" type="is-info" icon-left="clock" 
                @click="buscarDadosParcela()" title="Dados"></b-button>
        <b-modal :active.sync="exibirModal" has-modal-card trap-focus aria-role="dialog" aria-modal>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Dados - Parcela <b>{{this.$props.parcela.id}}</b></p>
                </header>
                <section class="modal-card-body">
                    <div class="panel">
                        <div class="panel-block is-justified-between" v-for="(item, index) in this.$props.parcela.dados" v-bind:key="index">
                            <h4>{{item.destinoMotivo}}</h4>
                            <span><b>{{ item.motivoId }}</b> {{item.motivoDescricaoInterno}}</span>
                            <span>{{ item.dataHora | moment('DD/MM/YYYY HH:mm') }}</span>
                            <span v-if="item.dados">{{ item.dados }}</span>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-pulled-right" type="button" @click="exibirModal = false">Fechar</button>
                </footer>
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        props: [
            'parcela'
        ],
        data() {
            return {
                exibirModal: false
            }
        },
        methods: {
            buscarDadosParcela(){
                //ajax para buscar os dados
                this.$http.get(`/api/financeiro/dadosparcela?chave=${this.$props.parcela.id}`).then(res => {
                    this.$props.parcela.dados = res.data;
                    //modal exibindo o resultado
                    this.exibirModal = true;
                });

            }
        }
    }
</script>