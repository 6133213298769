<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('TIPOCONVENIOCADASTRO.SALVANDOTIPO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOCONVENIOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input maxlength="50" type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <searchIdName :id.sync="model.convenioPadraoId" :label="$t('TIPOCONVENIOCADASTRO.CONVPADRAO')" table="Convenio"></searchIdName>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-quarter">
                                    <ValidationProvider name="prazoPendente" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOCONVENIOCADASTRO.PRAZOPENDENTE')"
                                                    :message="errors"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input type="number" v-model="model.prazoPendente"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-quarter">
                                    <ValidationProvider name="prazoAnalise" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOCONVENIOCADASTRO.PRAZOANALISE')"
                                                    :message="errors"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input type="number" v-model="model.prazoAnalise"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-quarter">
                                    <ValidationProvider rules="required" name="Tipo de Código" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOCONVENIOCADASTRO.TIPOCODIGO')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-select :placeholder="$t('TIPOCONVENIOCADASTRO.SELECIONETIPO')" v-model="model.exameCodigoTipo.id">
                                                <option v-for="tipo in model.tiposCodigos"
                                                        :key="tipo.id"
                                                        :value="tipo.id">
                                                    {{ tipo.nome }}
                                                </option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div>   
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.reembolso" @change.native="model.particular = model.reembolso">
                                            {{$t('TIPOCONVENIOCADASTRO.REEMBOLSO')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.particular" @change.native="verificaReembolso()">
                                            {{$t('TIPOCONVENIOCADASTRO.PARTICULAR')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.faturaAberta">
                                            {{$t('TIPOCONVENIOCADASTRO.FATURAABERTA')}}
                                        </b-switch>
                                    </div>
                                </div>
                            </article>
                        </article>                  
                    </div>                 
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-show="isInRole('tipoConvenio-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('TIPOCONVENIOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('TIPOCONVENIOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        components: {
            titlebar,
            searchIdName
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    particular: null,
                    reembolso: null,
                    faturaAberta: null,
                    prazoPendente: null,
                    prazoAnalise: null,
                    exameCodigoTipo: null,
                    tiposCodigos: [],
                    convenioPadraoId: null,
                },
                salvandoDados: false
            }
        },
        created() {
            let url = '/api/manutencao/tipoconvenio?id=' + this.$route.params.id
            if(!this.$route.params.id){
                url = '/api/manutencao/tipoconvenio'
            }
            this.$http.get(url)
                .then(res => {
                    this.model = res.data;
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('TIPOCONVENIOCADASTRO.MANUTENCAO'),
                    this.$t('TIPOCONVENIOCADASTRO.TIPOCONV'),
                    this.$route.params.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                this.$http.post(`/api/manutencao/tipoconvenio`, this.model)
                    .then(() => {
                        this.salvandoDados = false
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false
                        throw error
                    });
            },
            verificaReembolso(){
                if(!this.model.particular){
                    this.model.reembolso = false;
                }
            }
        }
    }
</script>