<template>
    <section>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                   {{$t('PORTALESTOQUE.SALVANDO')}}
                </div>
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-2">
                                    <b-field :label="$t('PORTALESTOQUE.CODIGO')">
                                        <b-input type="text" v-model="model.id" readonly></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-3">
                                    <ValidationProvider rules="required" name="referencia" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PORTALESTOQUE.REFERENCIA')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.referencia"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-3">
                                    <ValidationProvider rules="required" name="data" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PORTALESTOQUE.DATA')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-datepicker v-model="model.data"></b-datepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column" v-if="model.id">
                                    <b-field :label="$t('PORTALESTOQUE.SITUACAO')">
                                        <span v-if="model.situacao == 'Aguardando'" class="tag is-warning">
                                            {{ model.situacao }}
                                        </span>
                                        <span v-if="model.situacao == 'Cancelado'" class="tag is-danger">
                                            {{ model.situacao }}
                                        </span>
                                        <span v-if="model.situacao == 'Planejado'" class="tag is-info">
                                            {{ model.situacao }}
                                        </span>
                                        <span v-if="model.situacao == 'Entregue'" class="tag is-success">
                                            {{ model.situacao }}
                                        </span>
                                        <span v-if="model.situacao == 'Enviado'" class="tag is-default">
                                            {{ model.situacao }}
                                        </span>
                                        <span v-if="model.situacao == 'Recebido'" class="tag is-primary">
                                            {{ model.situacao }}
                                        </span>
                                    </b-field>
                                </div>
                            </div>  
                            <div class="columns">
                                <div class="column is-half">
                                    <b-field :label="$t('PORTALESTOQUE.LOCAL')">
                                        <b-select :placeholder="$t('PORTALESTOQUE.SELECIONELOCAL')" v-model="model.local.id" expanded>
                                            <option v-for="(item, index) in locaisConvenio"
                                                    v-bind:key="index"
                                                    :value="item.id">
                                                {{item.nome}}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>

                                <div class="column is-half">
                                    <b-field :label="$t('PORTALESTOQUE.ESTOQUE')">
                                        <b-select :placeholder="$t('PORTALESTOQUE.SELECIONEESTOQUE')" v-model="model.estoque.id" expanded>
                                            <option v-for="(item, index) in estoquesConvenio"
                                                    v-bind:key="index"
                                                    :value="item.id">
                                                {{item.nome}}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>              
                        </article>                
                    </div>  
                    <div class="tile is-parent is-vertical is-3">    
                        <historico-alteracoes :tabela="'PedidoInterno'" :chave="model.id"></historico-alteracoes>             
                    </div>                  
                </div>
                <article class="tile is-child box">
                    <cadastro-generico :colunas="colunasItens" :label="$t('PORTALESTOQUE.ITENS')" :items.sync="model.itens" :isPedido="true" @escolhidoId="escolheuConsumivel($event)" :estoqueId="model.estoque.id"></cadastro-generico>
                </article>
                <br/>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-info" v-if="model.situacao == 'Enviado'"
                                      :loading="salvandoDados"
                                      @click="receberPedido();"
                                      icon-left="check-circle">
                                {{$t('PORTALESTOQUE.RECEBER')}}
                            </b-button>
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('PORTALESTOQUE.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('PORTALESTOQUE.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters,mapState } from 'vuex'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import cadastroGenerico from '@/components/cadastroGenerico.vue'

    export default {
        components: {
            cadastroGenerico,
            historicoAlteracoes
        },
        data() {
            return {
                model: {
                    id: 0,
                    referencia: null,
                    estoque: {
                        id: 0,
                        nome: ''
                    },
                    local: {
                        id: 0,
                        nome: ''
                    },
                },
                colunasItens: [
                    {valor: 'consumivel', label: 'Consumivel', colunas: [
                        {valor: 'id', label: 'Id do consumível', tipo:"numero", carregarCampo: "nome", carregarCampoComplementar: "unidade", carregarCampoComplementarSecundario: "preco"},
                        {valor: 'nome', label: 'Nome', tipo: 'autocomplete', carregarCampoComplementarSecundario: "preco"},
                        {valor: 'unidade' , label: 'Unidade', tipo:'texto', readonly: true },
                        ]
                        , dinamica: true
                        , tabela: 'Consumivel'
                    },
                    {valor:'quantidade', label: 'Quantidade', tipo:"numero", passos:"0.1"},
                    {valor:'preco', label: 'Valor Unitário', tipo:"numero", passos:"0.01"},
                    {valor:'valorTotal', label: 'Valor', tipo:"numero", passos:"0.01"},

                ],         
                salvandoDados: false,
                locaisConvenio: [],
                estoquesConvenio: []
            }
        },
        created() {
            this.$http.get(`/api/portal/GuiaLocaisConvenio`)
                .then(res => {
                    this.locaisConvenio = res.data.lista;

                    this.preencherLocal();
                })
            
            this.$http.get('/api/portal/EstoquesConvenio')
                .then(res => {
                    this.estoquesConvenio = res.data.lista;
                    
                    this.preencherEstoque();
                })                
            
            if(this.$route.params.pedidoId){
                this.salvandoDados = true;

                this.$http.get('/api/portal/pedido?id=' + this.$route.params.pedidoId)
                    .then(res => {
                        this.model = res.data;

                        this.salvandoDados = false;

                        this.preencherLocal();
                        this.preencherEstoque();

                        if (this.model.data != null) {
                            this.model.data = new Date(this.model.data);
                        }

                        this.model.itens = this.model.itens?.map(c => {
                            return {
                                ...c,
                                // eslint-disable-next-line
                                consumivel__id: c.consumivel?.id,
                                // eslint-disable-next-line
                                consumivel__nome: c.consumivel?.nome,
                                // eslint-disable-next-line
                                consumivel__unidade: c.consumivel?.unidade,
                            }
                        });

                        this.colunasItens = [...this.colunasItens];
                    });                
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([                
                'unidades'
            ])            
        },
        methods: {
            preencherLocal(){
                if(this.salvandoDados || this.locaisConvenio.length == 0){
                    return;
                }

                if(this.model.local.id == 0){
                    this.model.local.id = this.locaisConvenio[0].id;
                }
            },
            preencherEstoque(){
                if(this.salvandoDados || this.estoquesConvenio.length == 0){
                    return;
                }

                if(this.model.estoque.id == 0){
                    this.model.estoque.id = this.estoquesConvenio[0].id;
                }
            },
            onSubmit(receber) {
                if(this.model.itens == null || this.model.itens.length == 0){
                    this.$buefy.dialog.alert({
                        title: this.$t('PORTALESTOQUE.ITENS'),
                        message: this.$t('PORTALESTOQUE.ITENSOBRIGATORIOS'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }

                if(this.model.itens.filter(item => item.quantidade == null || item.quantidade < 0).length > 0){
                    this.$buefy.dialog.alert({
                        title: this.$t('PORTALESTOQUE.ITENS'),
                        message: this.$t('PORTALESTOQUE.QUANTIDADESOBRIGATORIAS'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }

                this.salvandoDados = true;
                
                if(this.model.id == null){
                    this.model.id = 0
                }
                
                const pedido = {
                    pedidoInterno: this.model, 
                    receber: receber
                };

                this.$http.post(`/api/portal/pedido`, pedido)
                        .then(() => {
                            this.salvandoDados = false;
                            this.$router.back();
                        })
                        .catch((error) => {
                            this.salvandoDados = false;
                            throw error
                        });
            },
            receberPedido(){
                this.onSubmit(true);
            }
        }
    }
</script>