<template>
    <section>
        <b-loading :active.sync="carregando"></b-loading>

        <span class="is-flex is-justify-content-space-between">
            <titlebar :title-stack="titleStack"></titlebar>
            <div class="level-item" style="margin-right: 21vw;">
                <b-button type="is-danger"
                          :loading="loading"
                          native-type="button"
                          icon-left="close-circle"
                          @click="removerExame">
                    {{$t('EXAMEVALIDACAO.NAOREALIZOEXAME')}}
                </b-button>
            </div>
        </span>

        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-9">
                <article class="tile is-child box">

                    <div class="columns">
                        <div class="column is-2">
                            <ValidationProvider rules="required" name="apelido" v-slot="{ errors, valid }">
                                <b-field :label="$t('EXAMEVALIDACAO.APELIDO')"
                                    ref="apelido"
                                            :type="{ 'is-danger': errors[0] || apelidoInvalido, 'is-success': valid }"
                                            :message="getErrosApelido(errors)">
                                    <b-input type="text" v-model="model.apelido" maxlength="10" :has-counter="true" @blur="verificaApelidoExame"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>         
                        <div class="column">
                            <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                <b-field :label="$t('EXAMEVALIDACAO.NOME')"
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                            :message="errors">
                                    <b-input type="text" v-model="model.nome" maxlength="150" :has-counter="true"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>                                                                                                              
                    </div>

                    <div class="columns">
                        <div class="column">
                                <b-field :label="$t('EXAMEVALIDACAO.SETOR')">
                                <b-select v-model="model.setor.id" expanded>
                                    <option
                                        v-for="option in setores"
                                        :value="option.value"
                                        :key="option.value">
                                        {{ option.value }} - {{ option.text }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>

                         <div class="column">
                                <b-field :label="$t('EXAMEVALIDACAO.GEN')">
                                <b-select v-model="model.genero" expanded>
                                    <option value="Todos" > Todos</option>
                                    <option value="M">
                                        {{$t('EXAMEVALIDACAO.MASC')}}
                                    </option>
                                    <option value="F">
                                        {{$t('EXAMEVALIDACAO.FEM')}}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>

                        <div class="column">
                                <b-field :label="$t('EXAMEVALIDACAO.ETQNUMERO')" expanded>
                                    <b-tooltip expanded :label="$t('EXAMEVALIDACAO.CLIQUESETA')"
                                    type="is-dark"
                                    position="is-top" >
                                    <b-input type="number" icon="forward" v-model="model.agrupamentoDeAmostrasNumero" icon-clickable @icon-click="buscarProximoNumeroEtiqueta" expanded></b-input>                                            
                                </b-tooltip>
                            </b-field>
                        </div>

                        <div class="column">
                                <b-field :label="$t('EXAMEVALIDACAO.ORDEM')" expanded>
                                    <b-tooltip expanded :label="$t('EXAMEVALIDACAO.CLIQUESETA')"
                                    type="is-dark"
                                    position="is-top" >
                                    <b-input type="number" icon="forward" v-model="model.ordemNoResultado" icon-clickable @icon-click="buscarProximoNumeroOrdemRestado" expanded></b-input>                                            
                                </b-tooltip>
                            </b-field>
                        </div>                                
                    </div>

                    <div class="columns">
                        <div class="column">
                                <b-field :label="$t('EXAMEVALIDACAO.QTDEAMOSTRA')">
                                <b-input type="number" v-model="model.quantidadeDeAmostras" max="99999"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                                <b-field :label="$t('EXAMEVALIDACAO.QTDEETQ')">
                                <b-input type="number" v-model="model.quantidadeDeViasDeEtiqueta" max="99999"></b-input>
                            </b-field>
                        </div>                                  
                        <div class="column">
                                <b-field :label="$t('EXAMEVALIDACAO.PRAZODIA')">
                                <b-input type="number" v-model="model.prazoDeEntrega" max="99999"></b-input>
                            </b-field>
                        </div>  
                        <div class="column">
                                <b-field :label="$t('EXAMEVALIDACAO.PRAZOHORA')">
                                <b-input type="number" v-model="model.prazoDeEntregaHoras" max="99999"></b-input>
                            </b-field>
                        </div>                                                           
                        <div class="column">
                                <b-field :label="$t('EXAMEVALIDACAO.PRAZOMIN')">
                                <b-input type="number" v-model="model.prazoDeEntregaMinutos" max="99999"></b-input>
                            </b-field>
                        </div>                                                                       
                    </div>

                    <div class="columns">
                        <div class="column">
                            <b-field :label="$t('EXAMEVALIDACAO.MINJEJUM')">
                                <b-input type="number" v-model="model.minimoJejum" max="99999"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field :label="$t('EXAMEVALIDACAO.VOLDESEJADO')">
                                <b-input v-model="model.volumeDesejado"  maxlength="150" :has-counter="false"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field :label="$t('EXAMEVALIDACAO.VOLMIN')">
                                <b-input v-model="model.volumeMinimo"  maxlength="150" :has-counter="false"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field :label="$t('EXAMEVALIDACAO.SINONIMIA')">
                                <b-input v-model="model.sinonimia"  maxlength="500" :has-counter="false"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field :label="$t('EXAMEVALIDACAO.ULTIMOSRESUL')">
                                <b-input type="number" v-model="model.quantidadeDeUltimosResultados" max="99999"></b-input>
                            </b-field>
                        </div>
                        <div v-if="model.outrasDespesas" class="column">
                            <b-field :label="$t('EXAMEVALIDACAO.CODIGOTABELA')">
                                <b-select v-model="model.codigoTabela" expanded>
                                    <option value="00">
                                        00
                                    </option>
                                    <option value="18">
                                        18
                                    </option>
                                    <option value="19">
                                        19
                                    </option>
                                    <option value="20">
                                        20
                                    </option>
                                    <option value="22">
                                        22
                                    </option>
                                    <option value="90">
                                        90
                                    </option>
                                    <option value="98">
                                        98
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div v-if="model.outrasDespesas" class="column">
                            <ValidationProvider rules="required"
                                    name="código de despesas"
                                    v-slot="{ errors, valid }">
                                <b-field :label="$t('EXAMEVALIDACAO.CODIGODESPESA')" 
                                         :type="{ 'is-danger': errors[0], 'is-success': valid }" 
                                         :message="errors">
                                    <b-select v-model="model.codigoDespesa" expanded>
                                        <option value="01">
                                            01
                                        </option>
                                        <option value="02">
                                            02
                                        </option>
                                        <option value="03">
                                            03
                                        </option>
                                        <option value="05">
                                            05
                                        </option>
                                        <option value="07">
                                            07
                                        </option>
                                        <option value="08">
                                            08
                                        </option>
                                    </b-select>
                                </b-field>
                            </ValidationProvider>
                        </div>
                    </div>
                </article>
                                    
                <article class="tile is-child box">
                    <cadastro-generico :colunas="colunasRecipiente" :label="$t('EXAMEVALIDACAO.RECIP')" :items.sync="model.recipientes"></cadastro-generico>
                </article>

                <article class="tile is-child box">
                    <div class="panel-heading has-text-centered">
                            Dias de realização
                    </div>
                    <div class="panel-block tile is-12">
                        <b-checkbox-button v-model="diasDaSemana['0']" :native-value="true">{{$t('EXAMEVALIDACAO.DOMINGO')}}</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['1']" :native-value="true">{{$t('EXAMEVALIDACAO.SEGUNDA')}}</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['2']" :native-value="true">{{$t('EXAMEVALIDACAO.TERCA')}}</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['3']" :native-value="true">{{$t('EXAMEVALIDACAO.QUARTA')}}</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['4']" :native-value="true">{{$t('EXAMEVALIDACAO.QUINTA')}}</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['5']" :native-value="true">{{$t('EXAMEVALIDACAO.SEXTA')}}</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['6']" :native-value="true">{{$t('EXAMEVALIDACAO.SABADO')}}</b-checkbox-button>                                
                    </div>
                </article>

                <article class="tile is-child box">
                    <b-collapse class="card has-background-transparent"
                        animation="slide"
                        :open="isOpen"
                        @open="isOpen = !isOpen">
                        <div slot="trigger"
                            slot-scope="props"
                            class="card-header has-background-light has-text-centered"
                            role="button">
                            <p class="card-header-title">
                                {{$t('Campos de resultado')}}
                            </p>
                            <a class="card-header-icon">
                                <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                                <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                            </a>
                        </div>
                        <div class="panel-block column">
                            <div class="is-flex is-justify-content-flex-end">
                                <b-button class="mr-2" type="is-info" size="is-small" icon-left="eye" 
                                title="Ver PDF" @click="preVisualizarMascara()"></b-button>
                                <a :href="'/Manutencao/Mascara/' + model.id" class="button is-primary is-small" :title="$t('Abrir Máscara')">
                                    <b-icon icon="pencil" size="is-small"></b-icon>
                                </a>
                            </div>
                            <b-table v-if="mascara.campos.length != 0" :data="mascara.campos"
                                        :loading="carregando"
                                        striped
                                        hoverable>

                                <template >
                                    <b-table-column  v-slot="props" field="Nome" :label="$t('EXAMEVALIDACAO.NOME')">
                                        {{ props.row.nome }}
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="Titulo" :label="$t('EXAMEVALIDACAO.TITULO')">
                                        <input type="text" class="input" v-model="props.row.titulo"/>
                                    </b-table-column>

                                    <b-table-column v-slot="props" field="ValorPadrao" :label="$t('EXAMEVALIDACAO.VALORPADRAO')" centered>
                                        <input type="text" class="input" v-model="props.row.valorPadrao"/>
                                    </b-table-column>

                                    <b-table-column v-slot="props" field="Unidade" :label="$t('EXAMEVALIDACAO.UNIDADE')" centered>
                                        <input type="text" class="input" v-model="props.row.unidade"/>
                                    </b-table-column>

                                    <b-table-column v-slot="props" field="QtdeDecimais" :label="$t('EXAMEVALIDACAO.QNTDECIMAIS')" centered>
                                        <input type="text" class="input" v-model="props.row.decimais"/>
                                    </b-table-column>

                                    <b-table-column v-slot="props" field="ValorCriticoMinimo" :label="$t('EXAMEVALIDACAO.VALORCRITICOMINIMO')" centered>
                                        <input type="text" class="input" v-model="props.row.valorCriticoMinimo"/>
                                    </b-table-column>

                                    <b-table-column v-slot="props" field="ValorCriticoMaximo" :label="$t('EXAMEVALIDACAO.VALORCRITICOMAXIMO')" centered>
                                        <input type="text" class="input" v-model="props.row.valorCriticoMaximo"/>
                                    </b-table-column>
                                    
                                    <b-table-column v-slot="props" field="ValoresDeReferencia" centered>
                                        <b-button v-if="props.row.valoresDeReferencia != null && props.row.valoresDeReferencia.id != null" 
                                            type="is-info" @click="abrirValorDeReferencia(props.row.valoresDeReferencia)">
                                            {{$t('EXAMEVALIDACAO.VALORESREFERENCIA')}}
                                        </b-button>
                                    </b-table-column>
                                </template>
                            </b-table>
                        </div>
                    </b-collapse>
                </article>
            </div>
            
            <div class="tile is-parent">
                <article class="tile is-child">    
                        <article class="panel">
                        <div class="panel-block">
                            <b-switch v-model="model.selecionaVersaoDaMascara">
                                {{$t('EXAMEVALIDACAO.VERSAOMASC')}}
                            </b-switch>
                        </div>
                        <div class="panel-block">
                            <b-switch v-model="model.naoPublicaResultadosNaInternet">
                                 {{$t('EXAMEVALIDACAO.NAOPUBLICA')}}
                            </b-switch>
                        </div>    
                        <div class="panel-block">
                            <b-switch v-model="model.caraterPlantao">
                                {{$t('EXAMEVALIDACAO.EMPLANTAO')}}
                            </b-switch>
                        </div>  
                        <div class="panel-block">
                            <b-switch v-model="verificaValorTagFatu">
                               {{$t('EXAMEVALIDACAO.EXAFATU')}} 
                            </b-switch>
                        </div>
                        <div class="panel-block">
                            <b-switch v-model="verificaValorTagPerfil">
                               {{$t('EXAMEVALIDACAO.EXAPERFIL')}}
                            </b-switch>
                        </div>                      
                        <div class="panel-block">
                            <b-switch v-model="model.informarMedicamento">
                                {{$t('EXAMEVALIDACAO.INFOMED')}}
                            </b-switch>
                        </div>                          
                        <div class="panel-block">
                            <b-switch v-model="model.informarHipoteseDiagnostica">
                                {{$t('EXAMEVALIDACAO.INFOHIPOTESEDIAGNOSTICA')}}
                            </b-switch>
                        </div>   
                        <div class="panel-block">
                            <b-switch v-model="model.informarDUM">
                                {{$t('EXAMEVALIDACAO.INFODUM')}}
                            </b-switch>
                        </div>  
                        <div class="panel-block">
                            <b-switch v-model="model.informarPeso">
                               {{$t('EXAMEVALIDACAO.INFOPESO')}} 
                            </b-switch>
                        </div>  
                        <div class="panel-block">
                            <b-switch v-model="model.informarAltura">
                                {{$t('EXAMEVALIDACAO.INFOALTURA')}}
                            </b-switch>
                        </div>
                        <div class="panel-block">
                            <b-switch v-model="model.resultadoParcial">
                                {{$t('EXAMEVALIDACAO.LIBERAPARCIAL')}}
                            </b-switch>
                        </div> 
                        <div class="panel-block">
                            <b-switch v-model="model.bloqueioExameRepetido" type="is-danger">
                                {{$t('EXAMEVALIDACAO.BLOQREPETIDO')}}
                            </b-switch>
                        </div>          
                        <div class="panel-block">
                            <b-switch v-model="model.outrasDespesas">
                                {{$t('EXAMEVALIDACAO.OUTRASDESPESAS')}}
                            </b-switch>
                        </div> 
                        <div class="panel-block">
                            <b-switch v-model="model.laudoRestrito">
                                {{$t('EXAMEVALIDACAO.LAUDORESTRITO')}}
                            </b-switch>
                        </div>
                        <div class="panel-block">
                            <b-switch v-model="model.exameAliquota">
                                {{$t('EXAMEVALIDACAO.EXAMEALIQUOTA')}}
                            </b-switch>
                        </div>                         
                        <div class="panel-block">
                            <b-switch v-model="model.desativado" type="is-danger">
                                {{$t('EXAMEVALIDACAO.DESATIVADO')}}
                            </b-switch>
                        </div>
                        </article>   
                    <article class="panel">
                        <div class="panel-heading has-text-centered">
                            {{$t('EXAMEVALIDACAO.CODINTEG')}}
                        </div>
                        <div class="panel-block">
                            <table class="table is-bordered is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>{{$t('EXAMEVALIDACAO.TIPO')}}</th>
                                        <th>{{$t('EXAMEVALIDACAO.CODIGO')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in model.codigos" v-bind:key="index">
                                        <td>
                                            {{item.exameCodigoTipo.nome}}
                                        </td>
                                        <td>
                                            <input class="input" type="text" v-model="item.codigo">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>                               
                    </article>
                </article>
            </div>
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                              :loading="loading"
                              @click="processaValidacao()"
                              icon-left="check-circle">
                        {{$t('EXAMEVALIDACAO.PROXIMO')}}
                    </b-button>
                    <b-button type="is-danger"
                              native-type="button"
                              icon-left="close-circle"
                              @click="$router.push({ name: 'examesvalidacao' });">
                         {{$t('EXAMEVALIDACAO.CANCELAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>
<style lang="scss" scoped>
    .is-expanded{
        width: 100%;
        span {
            width: 100%;
        }
    }
</style>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import debounce from 'lodash/debounce'
    import cadastroGenerico from '@/components/cadastroGenerico.vue'
    import modalValidacaoValorReferencia from '@/components/manutencao/modalValidacaoValorReferencia'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    agrupamentoDeAmostrasNumero: null,
                    apelido: null,
                    aplicacaoClinica: null,
                    caraterPlantao: false,
                    codigoIntegracao: null,
                    codigos: [], //{exameCodigoTipo: {id: 3, nome: "DCNET ESP"}, codigo: "1014"}
                    consumiveisExame: [], //{id: 2, consumivel: {id: 44, nome: "Teste Treinamento"}, quantidade: 1, custoUnitario: 2}
                    criteriosRejeicao: null,
                    diasRealizacao: null,
                    examesComplementares: [],
                    informacoesAoLaboratorio: null,
                    informacoesAoPaciente: null,
                    informarAltura: false,
                    informarDUM: false,
                    informarMedicamento: false,
                    informarHipoteseDiagnostica: false,
                    informarPeso: false,
                    mascaras: [], //{nome: "1", versao: 1, exame: null, mascaraHtml: null, mascaraHtmlUltimaModificacao: null,…}
                    minimoJejum: null,
                    naoExibirLaudoWebPendente: false,
                    naoPublicaResultadosNaInternet: false,
                    nome: null,
                    ordemNoResultado: null,
                    pesquisas: [], //{id: 7, nome: "Documentos do Paciente"}
                    pop: null,
                    prazoDeEntrega: null,
                    prazoDeEntregaHoras: null,
                    prazoDeEntregaMinutos: null,
                    precos: [], //{tabelaPreco: {id: 4, nome: "AMB", tipo: "AMB", valoresCBHPM: null}
                    quantidadeDeAmostras: null,
                    quantidadeDeViasDeEtiqueta: null,
                    rascunho: null,
                    recipientes: [], //{id: 5001, material: {id: 2, nome: "Soro", codigosIntegrador: null}
                    resultadoParcial: false,
                    bloqueioExameRepetido: false,
                    laudoRestrito: false,
                    selecionaVersaoDaMascara: false,
                    setor: {id: 0, nome: null},
                    sinonimia: null,
                    volumeDesejado: null,
                    volumeMinimo: null,
                    codigosIntegrador: [],
                    genero: null,
                    quantidadeDeUltimosResultados: null,
                    codigoDespesa: null,
                    codigoTabela: "00",
                    examePerfil: false,
                },
                isOpen: false,
                setores: [],
                colunasRecipiente: [
                    {valor: 'id', label: 'Código', readonly: true, valorPadrao: 0 },
                    {valor: 'material', label: 'Material', tipo: 'select', opcoes: [] },
                    {valor: 'conservante', label: 'Conservante', tipo: 'select', opcoes: [] },
                    {valor: 'estabilidadeAmbiente', label: 'Estabilidade Ambiente (h)', tipo: 'numero', valorPadrao: 0},
                    {valor: 'estabilidadeRefrigerado', label: 'Estabilidade Refrigerado (h)', tipo: 'numero', valorPadrao: 0},
                    {valor: 'estabilidadeCongelado', label: 'Estabilidade Congelado (h)', tipo: 'numero', valorPadrao: 0}
                ],
                colunasConsumiveisExames: [
                    {valor: 'consumivel', label: 'Consumivel', colunas: [
                        {valor: 'id', label: 'Id do Consumível', tipo:"numero", carregarCampo: "nome"}
                        ,{valor: 'nome', label: 'Nome', tipo: 'autocomplete'}]
                        , dinamica: true
                        , tabela: 'Consumivel'
                    },                    
                    {valor:'status', label: 'Status', tipo:'select', opcoes: [] },
                    {valor:'compartilhado', label: 'Compartilhado', tipo:'select', opcoes: [] },
                    {valor:'quantidade', label: 'Quantidade', tipo:"numero", passos:"0.1"},
                    {valor:'custoUnitario', label: 'Preço Unitário', tipo:"numero", passos:"0.1"},
                    {valor:'subtotal', label: 'Subtotal', tipo:"subtotal", fn: (row) => {
                        return row.custoUnitario * row.quantidade;
                    }}
                ],
                colunasPesquisaClinica: [
                    {valor: 'id', label: 'Id da Pesquisa', tipo:"numero", carregarCampo: "nome", carregarCampoId: 'id', tabela: 'Pesquisa'},
                    {valor: 'nome', label: 'Nome', tipo:"autocomplete", dinamica: true, tabela: "Pesquisa"}
                ],
                colunasExamesComplementares: [
                    {valor: 'id', label: 'Id do Exame', tipo:"numero", carregarCampo: "nome", carregarCampoId: 'id', tabela: 'Exame', filtroFixo: {exameId: this.model?.id}, campoIdComponente: 'id'},
                    {valor: 'nome', label: 'Nome', tipo:"autocomplete", dinamica: true, tabela: "Exame"}
                ],
                carregando: false,
                diasDaSemana: {
                    '0': false,
                    '1': false,
                    '2': false,
                    '3': false,
                    '4': false,
                    '5': false,
                    '6': false,
                },
                apelidoInvalido: false,
                
                loading: false,
                mascara: null,
                exameAtualizado: null,
            };
        },
        components: {
            titlebar,
            cadastroGenerico,
        },
        created() {
            try {
                this.carregando = true;

                this.$http.get('/api/manutencao/setores?all=true')
                                .then(res => {
                                    this.setores = res.data;
                            }).catch(e => {
                                this.carregando = false;
                                throw e;

                            });

                const id = this.$route.params.id ? this.$route.params.id : '';
                const colunaExameComplementarFiltroFixo = this.colunasExamesComplementares?.find(c => c.carregarCampo);
                if (colunaExameComplementarFiltroFixo){
                    colunaExameComplementarFiltroFixo.filtroFixo = {
                        exameId: id
                    }
                }

                this.$http.get('/api/manutencao/exame?id=' + id)
                    .then(res => {
                        const dados = {...res.data};
                        dados.recipientes = dados.recipientes?.map(r => {
                            return {
                                ...r,
                                conservanteId: r.conservante?.id                               
                            }
                        });
                        this.model = dados;
                        const material = this.colunasRecipiente.find(r => r.valor === 'material');
                        if (material){
                            material.opcoes = this.model.materiais;
                        }
                        const conservante = this.colunasRecipiente.find(r => r.valor === 'conservante');
                        if (conservante){
                            conservante.opcoes = this.model.conservantes
                        }
                        //Força a recarga das colunas no componente
                        this.colunasRecipiente = [...this.colunasRecipiente];

                        this.model.consumiveisExame = this.model.consumiveisExame?.map(c => {
                            return {
                                ...c,
                                // eslint-disable-next-line
                                consumivel__id: c.consumivel?.id,
                                // eslint-disable-next-line
                                consumivel__nome: c.consumivel?.nome
                            }
                        });

                        this.$http.get('/api/manutencao/ConsumiveisExamesCompartilhado')
                            .then(res => {
                                const consumivelExameCompartilhado = this.colunasConsumiveisExames.find(r => r.valor === 'compartilhado');
                                if (consumivelExameCompartilhado){
                                    consumivelExameCompartilhado.opcoes = res.data.map(item => {
                                        return {
                                            id: item.id,
                                            nome: item.nome
                                        }
                                    });
                                }
                                //Força a recarga das colunas no componente
                                this.colunasConsumiveisExames = [...this.colunasConsumiveisExames];
                            })

                        this.$http.get('/api/manutencao/consumiveisExamesStatus')
                            .then(res => {
                                const status = this.colunasConsumiveisExames.find(r => r.valor === 'status');
                                if (status){
                                    status.opcoes = res.data.map(item => {
                                        return {
                                            id: item.id,
                                            nome: item.nome
                                        }
                                    });
                                }
                                //Força a recarga das colunas no componente
                                this.colunasConsumiveisExames = [...this.colunasConsumiveisExames];
                            })

                        this.$http.get('/api/manutencao/mascara?id=' + this.model.id)
                            .then(res => res.data)
                            .then(data => {
                                this.mascara = data;
                            });


                        if (this.model.diasRealizacao){
                            this.model.diasRealizacao?.forEach(d => {
                                this.diasDaSemana[d?.toString()] = true;
                            });
                        }else{
                            Object.keys(this.diasDaSemana).forEach(k => {
                                this.diasDaSemana[k] = true;
                            })
                        }

                        delete this.model.mascaras;

                        this.model.precos = this.model.precos?.filter(x => !x.tabelaPreco.desativado).map(p => {

                            p.valor = parseFloat(p.valor).toFixed(2);

                            if(isNaN(p.valor)){
                                p.valor = 0;
                            }

                            if (!this.model.id){
                                delete p.exame;
                                return p;
                            }else{
                                return {
                                    ...p,
                                    exame: {
                                        id: this.model.id ,
                                        nome: this.model.nome ,
                                        apelido: this.model.apelido,
                                    }
                                }
                            }
                        })

                        this.$nextTick(() => {
                            this.$refs.apelido.$el.getElementsByClassName('input')[0].focus()
                        });
                }).catch(e => {
                    this.carregando = false;
                    throw e;
                });
            }finally{
                this.carregando = false;
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('EXAMEVALIDACAO.MANUTENCAO'),
                    this.$t('EXAMEVALIDACAO.EXAME'),
                    (this.model.id && this.model.id != 0) ? this.model.id : this.$t('SISTEMA.NOVO'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            verificaValorTagPerfil: {
                get(){
                    return this.model.examePerfil
                },
                set(val){
                    this.model.examePerfil = val
                }
            },
            verificaValorTagFatu:{
                get(){
                    return this.model.naoExibirLaudoWebPendente
                },
                set(val){
                    this.model.naoExibirLaudoWebPendente = val
                }
            }
        },
        methods: {
            loadAsyncData: debounce(function () {
                try {
                    this.carregando = true;

                    this.$http.get('/api/manutencao/setores?all=true')
                                    .then(res => {
                                        this.setores = res.data;
                                }).catch(e => {
                                    this.carregando = false;
                                    throw e;

                                });

                    const id = this.$route.params.id ? this.$route.params.id : '';
                    const colunaExameComplementarFiltroFixo = this.colunasExamesComplementares?.find(c => c.carregarCampo);
                    if (colunaExameComplementarFiltroFixo){
                        colunaExameComplementarFiltroFixo.filtroFixo = {
                            exameId: id
                        }
                    }

                    this.$http.get('/api/manutencao/exame?id=' + id)
                        .then(res => {
                            const dados = {...res.data};
                            dados.recipientes = dados.recipientes?.map(r => {
                                return {
                                    ...r,
                                    conservanteId: r.conservante?.id                               
                                }
                            });
                            this.model = dados;
                            const material = this.colunasRecipiente.find(r => r.valor === 'material');
                            if (material){
                                material.opcoes = this.model.materiais;
                            }
                            const conservante = this.colunasRecipiente.find(r => r.valor === 'conservante');
                            if (conservante){
                                conservante.opcoes = this.model.conservantes
                            }
                            //Força a recarga das colunas no componente
                            this.colunasRecipiente = [...this.colunasRecipiente];

                            this.model.consumiveisExame = this.model.consumiveisExame?.map(c => {
                                return {
                                    ...c,
                                    // eslint-disable-next-line
                                    consumivel__id: c.consumivel?.id,
                                    // eslint-disable-next-line
                                    consumivel__nome: c.consumivel?.nome
                                }
                            });

                            this.$http.get('/api/manutencao/ConsumiveisExamesCompartilhado')
                            .then(res => {
                                const consumivelExameCompartilhado = this.colunasConsumiveisExames.find(r => r.valor === 'compartilhado');
                                if (consumivelExameCompartilhado){
                                    consumivelExameCompartilhado.opcoes = res.data.map(item => {
                                        return {
                                            id: item.id,
                                            nome: item.nome
                                        }
                                    });
                                }
                                //Força a recarga das colunas no componente
                                this.colunasConsumiveisExames = [...this.colunasConsumiveisExames];
                            })

                            this.$http.get('/api/manutencao/consumiveisExamesStatus')
                            .then(res => {
                                const status = this.colunasConsumiveisExames.find(r => r.valor === 'status');
                                if (status){
                                    status.opcoes = res.data.map(item => {
                                        return {
                                            id: item.id,
                                            nome: item.nome
                                        }
                                    });
                                }
                                //Força a recarga das colunas no componente
                                this.colunasConsumiveisExames = [...this.colunasConsumiveisExames];
                            })
                            
                            this.$http.get('/api/manutencao/mascara?id=' + this.model.id)
                                .then(res => res.data)
                                .then(data => {
                                    this.mascara = data;
                                });


                            if (this.model.diasRealizacao){
                                this.model.diasRealizacao?.forEach(d => {
                                    this.diasDaSemana[d?.toString()] = true;
                                });
                            }else{
                                Object.keys(this.diasDaSemana).forEach(k => {
                                    this.diasDaSemana[k] = true;
                                })
                            }

                            delete this.model.mascaras;

                            this.model.precos = this.model.precos?.filter(x => !x.tabelaPreco.desativado).map(p => {

                                p.valor = parseFloat(p.valor).toFixed(2);

                                if(isNaN(p.valor)){
                                    p.valor = 0;
                                }

                                if (!this.model.id){
                                    delete p.exame;
                                    return p;
                                }else{
                                    return {
                                        ...p,
                                        exame: {
                                            id: this.model.id ,
                                            nome: this.model.nome ,
                                            apelido: this.model.apelido,
                                        }
                                    }
                                }
                            })

                            this.$nextTick(() => {
                                this.$refs.apelido.$el.getElementsByClassName('input')[0].focus()
                            });
                    }).catch(e => {
                        this.carregando = false;
                        throw e;
                    });
                }finally{
                    this.carregando = false;
                }
            },1000),
            processaValidacao(){
                this.loading = true;
                this.atualizaExame();
            },
            validaExame(){
                this.$http.get(`/api/manutencao/ValidaExame?Id=${this.model.id}`)
                    .then(res => {
                        if(res.ok){
                            this.proximoValidar();
                        }
                    })
                    .catch(err => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    });
            },
            atualizaExame() {
                if (this.model.apelido == null || this.model.apelido == "") {
                    return this.$buefy.toast.open({
                        duration: 1000,
                        message: this.$t('EXAMECADASTRO.ALERTACAMPOAPELIDO'),
                        type: 'is-danger',
                        queue: false
                    })
                }

                if (this.model.nome == null || this.model.nome == "") {
                    return this.$buefy.toast.open({
                        duration: 1000,
                        message: this.$t('EXAMECADASTRO.ALERTACAMPONOME'),
                        type: 'is-danger',
                        queue: false
                    })
                }

                if (this.model.codigoDespesa == null && this.model.outrasDespesas) {
                    return this.$buefy.toast.open({
                        duration: 1000,
                        message: "O campo código despesa deve ser preenchido",
                        type: 'is-danger',
                        queue: false
                    })
                }

                if((this.model.codigoTabela == null || this.model.codigoTabela == "") && this.model.outrasDespesas) {
                    this.model.codigoTabela = "00";
                }
                
                const newItem = Object.assign({}, this.model);
                if (!this.$route.params.id){
                    delete newItem.id;
                }

                const adicionarExameAosItens = (nomeArray, variavel) => {
                    if (variavel[nomeArray]){
                        variavel[nomeArray] = variavel[nomeArray]?.map(c => {
                            return {
                                ...c,
                                exame: {id: this.model.id, nome: this.model.nome, apelido: this.model.apelido}
                            }
                        })
                    }
                }

                adicionarExameAosItens('consumiveisExame', newItem);
                adicionarExameAosItens('examesComplementares', newItem);
                adicionarExameAosItens('pesquisas', newItem);
                adicionarExameAosItens('recipientes', newItem);             

                if (newItem.codigosIntegrador){
                    newItem.codigosIntegrador = newItem.codigosIntegrador.map(i => {
                        return {
                            codigo: i.codigo,
                            integradorExameId: {
                                codigo: i.codigo,
                                integradorId: i.integradorId
                            }
                        }
                    }).filter(i => i.integradorExame?.integradorId?.toString()?.trim());
                }

                newItem.examesComplementares = newItem.examesComplementares?.map(e => {
                    return e['__resultado'];
                })

                let diasDaSemana = '';
                const addToDiasDaSemana = (valor) => {
                    if (!diasDaSemana.length) diasDaSemana = '?';
                    else diasDaSemana += '&';

                    diasDaSemana += valor;
                }
                
                if (this.diasDaSemana['0']) addToDiasDaSemana('domingo=true');
                if (this.diasDaSemana['1']) addToDiasDaSemana('segunda=true');
                if (this.diasDaSemana['2']) addToDiasDaSemana('terca=true');
                if (this.diasDaSemana['3']) addToDiasDaSemana('quarta=true');
                if (this.diasDaSemana['4']) addToDiasDaSemana('quinta=true');
                if (this.diasDaSemana['5']) addToDiasDaSemana('sexta=true');
                if (this.diasDaSemana['6']) addToDiasDaSemana('sabado=true');

                delete newItem.diasRealizacao;

                //desconsiderando valores com id null para consumiveis, pesquisas e exames complementares
                newItem.consumiveisExame = newItem.consumiveisExame?.filter(c => c.consumivel?.id != null); 
                newItem.pesquisas = newItem.pesquisas?.filter(c => c.id != null); 
                newItem.examesComplementares = newItem.examesComplementares?.filter(c => c.id != null);
                
                newItem.genero = (newItem.genero=="Todos") ? null : newItem.genero;

                //Formatando os valores de recipientes e consumiveis para que sejam 0 caso estejam null
                newItem.recipientes = newItem.recipientes?.map(r => {
                    if (!r.estabilidadeAmbiente) r.estabilidadeAmbiente = 0;
                    if (!r.estabilidadeRefrigerado) r.estabilidadeRefrigerado = 0;
                    if (!r.estabilidadeCongelado) r.estabilidadeCongelado = 0;
                    
                    return r;
                });

                newItem.consumiveisExame = newItem.consumiveisExame?.map(r => {

                    if (!r.custoUnitario) r.custoUnitario = 0;
                    if (!r.quantidade) r.quantidade = 0;

                    r.status = r.status.id;
                    r.compartilhado = r.compartilhado.id;

                    return r;
                });

                this.exameAtualizado = newItem;

                if(!this.apelidoInvalido){
                    this.$http.post(`/api/manutencao/exame${diasDaSemana}`, newItem)
                        .then(() => this.atualizaCamposResultado())
                        .catch(err => {
                            Object.keys(err.body.errors).forEach(x => {
                                this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                            })
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: 'Erro ao salvar exame',
                                type: 'is-danger',
                                queue: false
                            })
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            this.loading = false;
                        })  
                }
            },
            abrirValorDeReferencia(valorDeReferencia){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalValidacaoValorReferencia,
                    props: {
                        valorDeReferencia: valorDeReferencia
                    },
                    fullScreen: true,
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            atualizaCamposResultado(){
                this.mascara.exame = this.exameAtualizado;
                
                this.mascara.campos.map(campo => {
                    if(campo.recipiente.id == null)
                        campo.recipiente = null

                    if(campo.valoresDeReferencia.id == null)
                        campo.valoresDeReferencia = null
                })
                
                this.mascara.verificacao.map(verificacao => {
                    if(verificacao.nivel.id == null)
                        verificacao.nivel = null
                })
                
                this.mascara.codigosIntegrador = this.mascara.codigosIntegrador.map(codIntegrador => {
                    return {
                        ...codIntegrador, 
                        integrador: {
                            id : codIntegrador.integradorId
                        },
                    }
                })

                localStorage.setItem('exameId', this.mascara.exame.id);
                localStorage.setItem('versao', this.mascara.versao);
                localStorage.setItem('mascaraHtml', this.mascara.mascaraHtml);

                this.$http.post(`/api/manutencao/mascara`, this.mascara)
                    .then(() => this.validaExame())
                    .catch(err => {
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar campos de resultado',
                            type: 'is-danger',
                            queue: false
                        })
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        this.loading = false;
                    })
            },
            proximoValidar(){            
                const fieldName = localStorage.getItem('exameValidacaocolunaSelecionada');
                
                const params = [
                    `procurar=${localStorage.getItem('exameValidacaoProcurar')}`,
                    `fieldName=${fieldName}`,                    
                ].join('&')

                this.loading = true;
                this.$http.get(`/api/manutencao/proximoExameValidar?${params}`)
                    .then(({ data }) => {
                        this.loading = false;
                        if(data && data>0)
                            this.$router.push({ 
                                name: 'examevalidacao', params: {id: data}
                            }).then(() => {
                                this.loadAsyncData();
                            });
                    });
            },
            removerExame(){
                this.$buefy.dialog.confirm({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('EXAMEVALIDACAO.MSGDELECAO'),
                    type: 'is-danger',
                    hasIcon: true,
                    cancelText: this.$t('EXAMEVALIDACAO.NAO'),
                    confirmText: this.$t('EXAMEVALIDACAO.SIM'),
                    onConfirm: () => {
                        this.loading = true;
                        this.$http.get(`/api/manutencao/DeletarExame?Id=${this.model.id}`)
                            .then(res => res.data)
                            .then(data => {
                                if(data){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: this.$t('EXAMEVALIDACAO.MSGDELETADO'),
                                        type: 'is-warning',
                                        queue: false
                                    })

                                    this.loading = false;
                                    this.proximoValidar();
                                }
                                else{
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: this.$t('EXAMEVALIDACAO.MSGERRODELECAO'),
                                        type: 'is-warning',
                                        queue: false
                                    })
                                }
                            })
                            .catch(err => {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: err.body.title,
                                    type: 'is-danger',
                                    queue: false
                                })
                            });
                    }
                });
            },
            //Funções extraidas do arquivo mascara.vue (Acredito ser o que falta para o preview da mascara)
            //
            // preVisualizarMascara(){
            //     //TODO tirar o jquery
            //     valor = this.tratarHtmlMascara(this.mascara.mascaraHtml);

            //     this.isLoading = true;
            //     let url = `/api/manutencao/previewMascara`;
            //     this.$http.post(url, '\'<div>' + valor + '</div>\'', { responseType: 'arraybuffer' })
            //         .then(res => res.data)
            //         .then(data => {
            //             const file = new Blob([data], { type: 'application/pdf' });
            //             const fileURL = window.URL.createObjectURL(file);
            //             window.open(fileURL);

            //             this.isLoading = false;
            //         }).catch(e => {
            //             this.isLoading = false;
            //         });
            // },
            // tratarHtmlMascara(value){
            //     let mascaraJQ = $('<div>' + value + '</div>');
            //     let exame = this.model.exame;

            //     mascaraJQ.find('.MascaraCampo').replaceWith(function () {
            //         var conteudo = $(this).attr('name');

            //         conteudo = $('#Campos_' + $('[data-id=Id' + this.id + ']').data('index') + '__CodigoInterface').val();

            //         let unidade = $('#Campos_' + $('[data-id=Id' + this.id + ']').data('index') + '__Unidade').val();
            //         if (unidade != undefined && unidade != '') {
            //             conteudo = conteudo + ' (' + unidade + ')';
            //         }

            //         if ($(this).attr('name').toLowerCase() == 'material' && exame.recipientes.length > 0) {
            //             conteudo = exame.recipientes[0].material.nome;
            //         }

            //         if ($(this).attr('name').toLowerCase() == 'metodologia' || $(this).attr('name').toLowerCase() == 'metodo') {
            //             conteudo = $('#Campos_' + $('[data-id=Id' + this.id + ']').data('index') + '__ValorPadrao').val();
            //         }

            //         return '<span class="MascaraCampo">' + conteudo + '</span>';
            //     });

            //     let valrefcondicionados = '';
            //     mascaraJQ.find('.MascaraValRef').replaceWith(function () {
            //         let texto = $('#ValoresDeReferencia_' + $('[data-id=ValRef' + this.id + ']').data('index') + '__Texto').val();
            //         if (texto != undefined && texto != '') {
            //             return '<span class="MascaraValRef"><pre>' + texto + '</pre></span>';
            //         }
            //         let unidade = $('#ValoresDeReferencia_' + $('[data-id=ValRef' + this.id + ']').data('index') + '__Unidade').val();
            //         if (unidade == undefined) {
            //             unidade = '';
            //         }
            //         if ($('[data-valRefId=' + this.id + ']').length == 1) {
            //             return '<span class="MascaraValRef"><pre>' +
            //                 $('#ValoresDeReferencia_' + $('[data-id=ValRef' + this.id + ']').data('index') + '__Itens_0__ValorInicial').val() +
            //                 ' a ' +
            //                 $('#ValoresDeReferencia_' + $('[data-id=ValRef' + this.id + ']').data('index') + '__Itens_0__ValorFinal').val() +
            //                 ' ' + unidade +
            //                 '</pre></span>';
            //         }

            //         let nome = $(this).attr('name');
            //         let valrefidx = $('[data-id=ValRef' + this.id + ']').data('index');
            //         valrefcondicionados += '<tr><td colspan=3 style="text-align:center;background-color:#d9d9d9;">' + nome + '</td></tr>';
            //         $('[data-valRefId=' + this.id + ']').each(function (index) {
            //             valrefcondicionados += '<tr><td>' +
            //                 $('#ValoresDeReferencia_' + valrefidx + '__Itens_' + index + '__Expressao').val() +
            //                 '</td><td style="text-align:center">' +
            //                 $('#ValoresDeReferencia_' + valrefidx + '__Itens_' + index + '__ValorInicial').val() +
            //                 '</td><td style="text-align:center">' +
            //                 $('#ValoresDeReferencia_' + valrefidx + '__Itens_' + index + '__ValorFinal').val() +
            //                 '</td></tr>';
            //         });

            //         return '<span class="MascaraValRef"><pre>**' + nome + '**</pre></span>';
            //     });
            //     if (valrefcondicionados != '') {
            //         mascaraJQ.append('<br style="page-break-after: always;"/><table border="1" cellpadding="2" cellspacing="0" style="width:100%;">' +
            //             '<thead>' +
            //             '<tr><th colspan="3" style="text-align:center;background-color:black;color:white;">** Valores de Refer&ecirc;ncia Condicionados **</th></tr>' +
            //             '<tr>' +
            //             '<th>Condi&ccedil;&atilde;o</th>' +
            //             '<th style="text-align:center">Valor Inicial</th>' +
            //             '<th style="text-align:center">Valor Final</th>' +
            //             '</tr>' +
            //             '</thead>' +
            //             '<tbody>' +
            //             valrefcondicionados +
            //             '</tbody></table>');
            //     }

            //     return mascaraJQ.html();
            // },
            buscarProximoNumeroEtiqueta(){
                this.$http.get('/api/manutencao/proximoEtiquetaNumeroExame?setorId=' + this.model.setor?.id + `&_=${new Date().getTime()}`)
                .then(res => {
                    if (res.data != null){
                        this.model.agrupamentoDeAmostrasNumero = res.data;
                    }

                });

            },
            buscarProximoNumeroOrdemRestado(){
                 this.$http.get('/api/manutencao/proximoOrdemNoResultado?setorId=' + this.model.setor?.id + `&_=${new Date().getTime()}`)
                .then(res => {
                    if (res.data != null){
                        this.model.ordemNoResultado = res.data;
                    }

                });

            },
            getErrosApelido(erros){
                if (this.apelidoInvalido){
                    erros = [...erros, this.$t('EXAMEVALIDACAO.APELIDOEXISTE')]
                }
                return erros;
            },
            verificaApelidoExame(apelido){
                this.apelidoInvalido = false;

                this.$http.get(`/api/manutencao/VerificaApelidoExame?exameId=${this.model.id}&exameApelido=${encodeURIComponent(apelido.target.value)}`)
                .then(res => {
                    if (!res.data?.valido){
                        this.apelidoInvalido = true;
                        this.$forceUpdate();
                    }
                });                
            }
        }
    }
</script>