


<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALBLOQUEIO.BLOQUEARHORARIOS')}} - {{ tipoAgendamento.nome }}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification v-if="erroBloqueioHorarios"
                            type="is-danger">
                {{ erroBloqueioHorarios }}
            </b-notification>
            <div class="panel">
                <div class="panel-block">
                    <div class="field">
                        <b-switch v-model="acaoBloquear" @input=" (acaoBloquear) ? acaoBloquearLabel='Bloquear?' : acaoBloquearLabel = 'Desbloquear?' ">
                            {{ acaoBloquearLabel }}
                        </b-switch>
                    </div>
                </div>                
                <p class="panel-block">
                    <periodoHora :regraBloqueio="false" :dataInicial.sync="datas[0]" :dataFinal.sync="datas[1]" />                 
                </p>
                <p class="panel-block">
                    <b-field :label="$t('MODALBLOQUEIO.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-select expanded v-model="motivoBloqueioHorariosSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosBloqueioHorarios" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                    :loading="salvandoDados"
                      @click="confirmarBloqueioHorarios">{{$t('MODALBLOQUEIO.CONFIRMARBLOQUEIO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('MODALBLOQUEIO.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>

    import periodoHora from '@/components/periodoHora.vue'
    import moment from 'moment';    

    export default {
        props: {
            tipoAgendamento: Object,
            unidadeId: Number
        },
        data() {
            return {
                procurarMotivosBloqueioHorarios: '',
                listaMotivosBloqueioHorarios: [],
                motivoBloqueioHorariosSelecionado: null,
                erroBloqueioHorarios: null,
                datas: [
                    this.$moment().subtract(1, 'days').toDate(),
                    this.$moment().toDate()
                ],        
                acaoBloquear: true,        
                acaoBloquearLabel: 'Bloquear?',
                salvandoDados: false,
                eventos: []
            }
        },
        components: {            
            periodoHora
        },        
        computed: {
            filteredMotivosBloqueioHorarios() {
                return this.procurarMotivosBloqueioHorarios
                    ? this.listaMotivosBloqueioHorarios
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosBloqueioHorarios.toUpperCase()) > -1)
                    : this.listaMotivosBloqueioHorarios
            },
        },
        mounted() {
            this.loadListaMotivosBloqueioHorarios()
        },
        methods: {
 
            async loadDataDia(dataDiaFiltro){
                const params = [];
                
                params.push(`dataDiaFiltro=${this.$moment(dataDiaFiltro.setHours(0,0,0,0)).format('YYYY-MM-DDTHH:mm:ss')}`);                                     
                params.push(`unidadeId=${this.unidadeId}`);
                params.push(`tipoAgendamentoId=${this.tipoAgendamento.id}`);
                this.isLoading = true;

                try {
                    await this.$http.get(`/api/agenda/AgendaDia?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {


                                data?.lista.map(d => { return this.getEvento(d); }).forEach(e => {
                                    this.eventos.push(e);
                                });
                                                      
                        }                            
                                                                                                                
                        }).catch(e => {
                            this.salvandoDados=false;
                            throw e;
                        }).finally(() => {
                            this.$forceUpdate();                                 
                        });
                        
                } catch (e) {
                    console.error(e);
                    this.salvandoDados=false;
                }                
                
            }, 

            getEvento(d) {
                return {
                    ...d,
                    atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                    atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                    day: moment(d.dataHora).format('YYYY-MM-DD'),
                    hour: moment(d.dataHora).format('HH:mm'),
                    data: moment(d.dataHora)
                }
            },            
            loadListaMotivosBloqueioHorarios() {
                this.$http.get('/api/search/MotivosBloqueiosAgenda')
                    .then(m => {
                        this.listaMotivosBloqueioHorarios = m.data
                    })
            },
            async confirmarBloqueioHorarios() {


                if(this.motivoBloqueioHorariosSelecionado){

                    this.salvandoDados=true;

                    const params = {
                        tipoAgendamentoId:this.tipoAgendamento.id,
                        acaoBloquear:this.acaoBloquear,
                        dataInicial:this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss'),
                        dataFinal:this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss'),
                        motivoId:this.motivoBloqueioHorariosSelecionado,
                        unidadeId:this.unidadeId,
                        eventos: this.eventos
                    }


                    while (this.datas[0] <= this.datas[1]) {                                                                 
                        await this.loadDataDia(this.datas[0]);                        
                        this.datas[0].setDate(this.datas[0].getDate() + 1); 
                    }                    
                    
                    this.$http.post('/api/agenda/BloquearHorarios', params)
                    .then((res) => {
                        if (res.body.erro) {
                            this.erroBloqueioHorarios = res.body.erro;
                            this.salvandoDados=false;
                        } else {
                            this.$emit('loadData')
                            this.$emit('close')
                        }                        
                    }); 
                    
                    
                    this.salvandoDados=false;
                                
                } else {
                    this.$buefy.dialog.alert({
                        title: this.$t('MODALBLOQUEIO.MOTIVO'),
                        message: this.$t('MODALBLOQUEIO.AVISOMOTIVO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                }

                                            
            },

        }
    }
</script>